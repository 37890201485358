.kt-notice {
  background-color: #fff8f0;
  border-bottom: 1px solid #ffd8b2;
  padding: 1.5rem 0;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
  }

  &__text {
    text-align: center;
    
    h3 {
      color: #ff6b00;
      font-size: 1.4rem;
      margin-bottom: 0.75rem;
      font-weight: 600;
    }

    p {
      color: #666;
      margin-bottom: 0;
      font-size: 1.1rem;
      line-height: 1.5;
    }
  }
} 